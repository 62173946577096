<template>
    <div class="layout flex">
        <div class="menu">
            <Menu></Menu>
        </div>
        <div :class="['nav', menuSwitch ? 'menu-ani-open1' : 'menu-ani-close1']">
            <Header></Header>
            <div class="pageContainer">
                <div class="pageTab scrollbar-x">
                    <PageTab></PageTab>
                </div>
                <div class="content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from './menu.vue'
import Header from './header.vue'
import PageTab from './pageTab.vue'
import { mapState } from 'vuex'

export default {
    components:{Header, Menu, PageTab},
    data(){
        return {
            data: [{
                label: '一级 1',
                children: [{
                    label: '二级 1-1',
                    children: [{
                    label: '三级 1-1-1'
                    }]
                }]
                }, {
                label: '一级 2',
                children: [{
                    label: '二级 2-1',
                    children: [{
                    label: '三级 2-1-1'
                    }]
                }, {
                    label: '二级 2-2',
                    children: [{
                    label: '三级 2-2-1'
                    }]
                }]
                }, {
                label: '一级 3',
                children: [{
                    label: '二级 3-1',
                    children: [{
                    label: '三级 3-1-1'
                    }]
                }, {
                    label: '二级 3-2',
                    children: [{
                    label: '三级 3-2-1'
                    }]
                }]
            }],
            defaultProps: {
            children: 'children',
            label: 'label'
            }
      };
    },
    computed: {
        ...mapState(['menuSwitch']),
    },
    methods: {
        handleNodeClick(data) {
            console.log(data);
        }
    }
}
</script>

<style lang="scss" scoped>
.nav{
    min-width: 1000px;
}
.menu-ani-close1{
    animation: muneClose;
    animation-duration: 0.5s;
    width: calc(100vw - 64px);
}
.menu-ani-open1{
    animation: muneOpen;
    animation-duration: 0.5s;
    width: calc(100vw - 250px);
}
@keyframes muneClose {
    
    0%{
        width: calc(100vw - 250px);
    }
    100%{
        width: calc(100vw - 64px);
    }
}
@keyframes muneOpen {
    0%{
        width: calc(100vw - 64px);
    }
    100%{
        width: calc(100vw - 250px);
    }
}
.pageContainer{
    margin: 16px 16px 0 16px;
    overflow: auto;
    height: calc(100vh - 56px);
}
.pageContainer::-webkit-scrollbar{
    width: 6px;
}
.pageContainer::-webkit-scrollbar-thumb{
    width: 6px;
    background: #C6D0DE;
    border-radius: 2px;
}

.pageTab{
    flex-shrink: 0;
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    overflow-x: auto;
    
}
.content{
    background: #FFF;
    min-height: calc(100vh - 112px);
    padding: 20px 14px 50px;
}
</style>
  