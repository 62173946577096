<template>
    <div id="app" :class="[ deviceType === 'pc' ? 'app' : 'app2']">
        <!-- <layout v-if="$route.path.indexOf('/login') === -1"/> -->
        <router-view></router-view>
        <audio src="./../src/assets/jyq.mp3" ref="audio"></audio>
       
        <el-dialog
            
            :visible.sync="dialogVisible"
            :width="deviceType === 'pc' ? '30%' : '80%'"
            :before-close="handleClose">
            <img style="width: 100%;height: 100%;" src="./../src/assets/bannerleft.jpg" alt=""/>
            <br>
            <span style="margin-left: 22%;">请点击屏幕任意位置进入景缘官网</span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import layout from './components/layout/index.vue'
export default {
    name: 'App',
    components: {
        layout
    },
    computed: {
        ...mapState(['deviceType'])
    },
    data(){
        return {
            dialogVisible: true
        }
    },
    created(){
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
            this.$store.commit("change_deviceType", 'mobile')
        }else{
            this.$store.commit("change_deviceType", 'pc')
        }
    },
    methods: {
        handleClose(){
            this.dialogVisible =  false
            this.$refs.audio.play()
        }
    }
}
</script>

<style lang="scss" scoped>
#app{
    width: 100vw;
    height: 100vh;
    overflow: auto;
}
#app::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.app2{
    width: 100vw !important;
    height: 100vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.app2::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

</style>
