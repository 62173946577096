<template>
    <div :class="['menu', menuSwitch ? 'menu-ani-open' : 'menu-ani-close']">
        <!-- <img src="./../../assets/images/logo-white.png" alt="" class="logo" v-if="menuSwitch"/> -->
        <el-menu
            mode="vertical"
            :collapse="!menuSwitch"
            :default-active="defalutcActive"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#1F2D52"
            text-color="#bfcbd9"
            active-text-color="#FFF">
            <el-menu-item :index="`${index+1}-1`" v-for="(item, index) in menuList" :key="index" @click="toPath(item)">
                <i class="el-icon-menu"></i>
                <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
import router from './../../router'
export default {
    data(){
        return {
            // menu: router.options.routes.filter(item=>item.alwaysShow),
            defalutcActive: '1-1'
        }
    },
    watch: {
        $route: {
            handler(val){
                this.menuList?.forEach((item, index)=>{
                    if(val.path === item.path){
                        this.defalutcActive = `${index + 1}-1`
                    }
                })
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        ...mapState(['menuSwitch', 'menuList']),
    },
    created(){
        // console.log('menu', this.menu)
    },
    methods: {
        handleOpen(){},
        handleClose(){},
        toPath(item){
            this.$router.push({
                path: item.path
            })
            this.$store.commit("add_historyRecord", item)
            
        },
    }
}
</script>
  
<style lang="scss" scoped>
.menu{
    width: 250px;
    height: 100vh;
    background: #1F2D52;
    padding: 20px 0 0 0;
}
.menu-ani-close{
    animation: muneClose;
    animation-duration: 0.5s;
    width: 64px;
}
.menu-ani-open{
    animation: muneOpen;
    animation-duration: 0.5s;
    width: 250px;
}
@keyframes muneClose {
    0%{
        width: 250px;
    }
    100%{
        width: 64px;
    }
}
@keyframes muneOpen {
    0%{
        width: 64px;
    }
    100%{
        width: 250px;
    }
}
.logo{
    width: 132px;
    height: 30px;
    margin: 0 auto 20px;
    cursor: pointer;
}
::v-deep .el-menu .is-active{
    background: #1890FF !important;
}
::v-deep .el-menu{
    border: none;
}
</style>
  