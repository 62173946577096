import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => {})
};


Vue.use(VueRouter)

/* Layout */
import Layout from './../components/layout'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    hidden: true,
  },
  {
    path: '/home',
    name: 'home',
    hidden: true,
    meta: { title: 'home', icon: 'home', affix: false },
    component: () => import('../views/home.vue')
  },
  {
    path: '/duty',
    name: 'duty',
    hidden: true,
    meta: { title: 'duty', icon: 'duty', affix: false },
    component: () => import('../views/duty.vue')
  },
  {
    path: '/financialService',
    name: 'financialService',
    hidden: true,
    meta: { title: 'financialService', icon: 'financialService', affix: false },
    component: () => import('../views/financialService.vue')
  },
  {
    path: '/platformService',
    name: 'platformService',
    hidden: true,
    meta: { title: 'platformService', icon: 'platformService', affix: false },
    component: () => import('../views/platformService.vue')
  },
  {
    path: '/companyInfo',
    name: 'companyInfo',
    hidden: true,
    meta: { title: 'companyInfo', icon: 'companyInfo', affix: false },
    component: () => import('../views/companyInfo.vue')
  },
  
]

const router = new VueRouter({
  mode: 'hash', // require service support
  // base: '',
  routes: constantRoutes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.afterEach(() => {
  window.scrollTo(0,0)
  // document.documentElement.scrollTop = 0
})

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
