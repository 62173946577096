<template>
    <div class="header between">
        <div class="btns">
            <i class="el-icon-s-fold" @click="handleMenuChange" v-if="menuSwitch"></i>
            <i class="el-icon-s-unfold" @click="handleMenuChange" v-if="!menuSwitch"></i>
        </div>
        <div class="user">
            <el-dropdown trigger="click">
                <!-- <img src="" alt=""/> -->
                <span class="el-dropdown-link">
                    <i class="el-icon-user"></i>
                        {{userInfo.username || ''}}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapState(['menuSwitch', 'userInfo']),
    },
    methods: {
        logout(){
            this.$router.push({
                path: '/login'
            })
        },
        handleMenuChange(){
            this.$store.commit('change_menuSwitch', !this.menuSwitch)
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    background: #fff;
    height: 56px;
    box-sizing: border-box;
    padding: 0 16px;
    .btns{
        font-size: 36px;
        color: #333333;
        i{
            cursor: pointer;
        }
    }
    .user{
        cursor: pointer;
    }
}
</style>
